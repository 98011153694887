@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: -apple-system, "SF Pro Text", "Roboto", "Hind Siliguri", sans-serif;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #000;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 13px rgba(0, 0, 0, 0.12);
  z-index: 9999;
}

.title {
  font-weight: 600;
  font-size: calc(17px * (5vw / 100));
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  flex: 4 1;
  text-align: center;
  padding-left: 16px;
}

.title-android {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}


.title-bengali {
  font-weight: 700;
}

.back-icon {
  height: 24px;
  width: 24px;
}

.empty-header {
}

.container {
  padding-top: 10px;
  padding-left: 16px;
}

.container > h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  padding: 12px 0;
  text-align-last: left;
  width: 100%;
}

.container-fluid {
  padding: 16px;
}

.section-text {
  padding: 4px 0;
  text-align: left;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.slider > .slider-image {
  width: 50vw;
}

.slider > .slider-video {
  height: 60vh;
}

.slider-image-wide {
  width: 80vw;
}

iframe.full-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slider > .slider-left {
  padding: 10px;
}

.slider-left-wide {
  position: absolute;
  left: 10px;
}

.slider > .slider-right {
  padding: 10px;
}

.slider-right-wide {
  position: absolute;
  right: 10px;
}

.slider > .slider-dots {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #e5e5ea;
  margin: 0 6px;
  border-radius: 50%;
}

.dot.active {
  background-color: #ffcc00;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.menu-item:last-child {
  border: none;
}

.menu-item-brand {
  justify-content: flex-start;
}

.menu-item-brand > img {
  margin-right: 12px;
}

.swiper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.swiper > .swiper-image {
  width: 100%;
}

.swiper-image-high {
  height: 60vh;
  width: auto !important;
}

.swiper > .swiper-dots {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-info {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 34px 16px;
}

.info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 12px 0;
  gap: 12px 0;
  margin: 6px 0;
}

.swiper-info > .info.active {
  display: flex;
}

.info-text {
  width: 90%;
}

.info > .info-point {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 0px 8px;
  gap: 0px 8px;
}

.info-dot {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffcc00;
  border-radius: 12px;
  margin-right: 8px;
  color: #fff;
}

.info > .info-point-center {
  align-items: center;
}

.tag {
  border-radius: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #ffffff;
  padding: 0px 8px;
  margin: 8px 0;
}

.tag.tag-green {
  background-color: #30d158;
}

.tag.tag-blue {
  background-color: #007aff;
}

.tag.tag-cyan {
  background-color: #5ac8fa;
}

.tag.tag-red {
  background-color: #ff3b30;
}

.tag.tag-dark-green {
  background-color: #009626;
}

.tag.tag-yellow {
  background-color: #ffcc00;
}

.tag.tag-black {
  background-color: #000000;
}

.tag.tag-purple {
  background-color: #5856d6;
}

.tag.tag-gray {
  background-color: #c7c7cc;
}

.tag.tag-dark-purple {
  background-color: #af52de;
}

.tag.tag-orange {
  background-color: #ff9500;
}

.tag.tag-grey {
  background-color: #607d8b;
}

.info > .info-point.info-point-tags {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.info-point-tags > .info-text {
  width: 100%;
}

.info-icon {
  background-color: transparent;
}

.menu-item > .menu-item-title {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.red-text {
  color: #db4437;
}

.gap {
  width: 100%;
  height: 8px;
  background-color: #eceff0;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: -webkit-fill-available;
    padding-top: 16px;
    padding-bottom: 64px;
}

.selectHeader {
    width: 100%;
    padding: 32px 16px;
    font-size: 38px;
    flex: 1 1;
}

.selectBody {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 2 1;
}

.selectItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 12px 24px;
}

.selectItem.itemActive {
    border-left: 4px solid #ffd800;
}

.selectItem:not(:last-child) {
    border-bottom: 1px solid #cecece;
}

.nameNatural {
    font-size: 24px;
    margin-bottom: 4px;
}

.selectConfirm {
    width: 90%;
    padding: 16px 32px;
    border: none;
    border-radius: 32px;
    background-color: #ffd800;
    font-size: 24px;
    color: #000000;
}

.noTranslationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 32px;
}

