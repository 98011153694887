.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: -webkit-fill-available;
    padding-top: 16px;
    padding-bottom: 64px;
}

.selectHeader {
    width: 100%;
    padding: 32px 16px;
    font-size: 38px;
    flex: 1;
}

.selectBody {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 2;
}

.selectItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 12px 24px;
}

.selectItem.itemActive {
    border-left: 4px solid #ffd800;
}

.selectItem:not(:last-child) {
    border-bottom: 1px solid #cecece;
}

.nameNatural {
    font-size: 24px;
    margin-bottom: 4px;
}

.selectConfirm {
    width: 90%;
    padding: 16px 32px;
    border: none;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    background-color: #ffd800;
    font-size: 24px;
    color: #000000;
}
